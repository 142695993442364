
import TheHeader from "./components/layout/TheHeader.vue";
import TheFooter from "./components/layout/TheFooter.vue";
import TheSignalr from "./components/layout/TheSignalr.vue";
import EventView from "./components/content/Event/EventView.vue";
import FloatingBackToTop from "./components/content/FloatingBackToTop/FloatingBackToTop.vue";
// import MobileFooter from "./components/content/MobileFooter/MobileFooter.vue";
// import FloatingSwitchSite from "./components/content/FloatingSwitchSite/FloatingSwitchSite.vue";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
export default defineComponent({
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  components: {
    TheHeader,
    TheFooter,
    TheSignalr,
    EventView,
    FloatingBackToTop,
    // MobileFooter,
    // FloatingSwitchSite,
  },
  computed: {
    isLogin() {
      return this.$store.getters["identityServer/getLoginStatus"];
    },
    displayModal() {
      return this.$store.state.showModal;
    },
    didAutoLogout() {
      return this.$store.getters["identityServer/didAutoLogout"];
    },
  },
  watch: {
    didAutoLogout(curValue, oldValue) {
      let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
      if (curValue && curValue !== oldValue) {
        this.toast.success(this.$t("Auto LogOut in 5 Seconds"), {
          position: toastPosition,
          timeout: 5000,
          onClose: () => this.redirectHomePage(),
        });
      }
    },
    async isLogin(curVal) {
      if (curVal) {
        this.fetchWinover();
      }
    },
  },
  methods: {
    closeModal() {
      this.$store.commit("showModal", { status: false });
    },
    redirectHomePage() {
      this.$store.dispatch("identityServer/logOut");
      window.location.href = "/";
    },
    async fetchWinover() {
      const loginId = this.$store.getters["identityServer/getLoginId"];
      await this.$store.dispatch("memberAccount/fetchMemberWinover", {
        loginId: loginId,
        isFromDB: true,
      });
    },
  },
  async created() {
    await this.$store.dispatch("vendors/fetchVendor");
    await this.$store.dispatch("vendors/fetchVendorAccount");
    await this.$store.dispatch("identityServer/tryLogin");
    await this.$store.dispatch("ipAddress/fetchIPAddress");
    await this.$store.dispatch("language/fetchClientLanguageList");
  },
});
