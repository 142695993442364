
import { defineComponent } from "vue";
import HeaderNavBar from "@/components/content/Header/HeaderNavBar.vue";
// import HeaderLogin from "@/components/content/Header/HeaderLogin.vue";
// import MobileSubHeader from "@/components/content/Header/MobileSubHeader.vue";
import SideBar from "@/components/content/Header/SideBar.vue";
import BaseImage from "@/components/dynamic/BaseImage.vue";
// import LoginModal from "@/components/modal/LoginModal.vue";
// import SignUpModal from "@/components/modal/SignUpModal.vue";
// import ForgotPasswordModal from "@/components/modal/ForgotPasswordModal.vue";
// import ChangePasswordModal from "@/components/modal/ChangePasswordModal.vue";
// import ProfileModal from "@/components/modal/ProfileModal.vue";
// import BankInfoModal from "@/components/modal/BankInfoModal.vue";
// import DepositModal from "@/components/modal/DepositModal.vue";
// import WithdrawalModal from "@/components/modal/WithdrawalModal.vue";
// import InterchangeModal from "@/components/modal/InterchangeModal.vue";
// import GameIDInfoModal from "@/components/modal/GameIDInfoModal.vue";

export default defineComponent({
  data() {
    return {
      imgUrlDomain: process.env.VUE_APP_Media_Domain,
      logoUrl: "assets/images/kaya.png",
      scrollPosition: 0,
    };
  },
  computed: {
    isLogin() {
      return this.$store.getters["identityServer/getLoginStatus"];
    },
    isMobile() {
      return this.$isMobile();
    },
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
  components: {
    HeaderNavBar,
    // HeaderLogin,
    // MobileSubHeader,
    BaseImage,
    SideBar,
    // ForgotPasswordModal,
    // SignUpModal,
    // LoginModal,
    // ChangePasswordModal,
    // ProfileModal,
    // BankInfoModal,
    // DepositModal,
    // WithdrawalModal,
    // InterchangeModal,
    // GameIDInfoModal,
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
});
