<template>
  <div class="backToTopButtonContainer" v-if="showButton">
    <div
      id="backToTop"
      class="backToTopButton clickable"
      @click="scrollToTop"
    ></div>
    <label for="backToTop" class="backToTopText">
      {{ $t("FloatingBackToTop.BackToTop") }}
    </label>
  </div>
</template>

<style scoped>
.backToTopButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  position: fixed;
  bottom: 20%;
  right: 10%;
  z-index: 100;
  width: 4rem;
  text-align: center;
}

.backToTopButton {
  background-image: url("@/assets/arrow-down.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 2.5rem;
  background-color: rgba(0, 0, 0, 0.5);
  filter: invert(100%);
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  transform: rotate(180deg);
}

.backToTopText {
  font-size: 0.7em;
  font-weight: 600;
  white-space: nowrap;
}

@media (max-width: 900px) {
  .backToTopButtonContainer {
    bottom: 18%;
    left: 50%;
    width: 3rem;
    translate: -50%;
  }

  .backToTopButton {
    background-size: 1.5rem;
    width: 2.5rem;
    height: 2.5rem;
  }

  .backToTopText {
    font-size: 0.7em;
    font-weight: 600;
    white-space: normal;
  }
}
</style>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      scrollPosition: 0,
    };
  },
  computed: {
    showButton() {
      return this.scrollPosition > window.innerHeight / 2;
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
});
</script>
