<template>
  <div class="bannerContainer">
    <Carousel
      v-if="bannerListByLang.length !== 0"
      id="homeCarousel"
      class="bannerMax"
      :autoplay="4000"
      :wrap-around="true"
      pause-autoplay-on-hover
    >
      <slide v-for="(bImg, bIndex) in bannerList" :key="bImg.id">
        <BaseImage
          :imgSrc="bannerListByLang[bIndex].imgURL"
          mode="mainBanner"
          :isFullUrl="true"
          fluid-grow
          @click="bannerLink(bImg.url)"
        ></BaseImage>
      </slide>

      <template #addons>
        <Navigation />
        <Pagination />
      </template>
    </Carousel>

    <Carousel
      v-else
      id="homeCarousel"
      class="bannerMax"
      :autoplay="4000"
      :wrap-around="true"
      pause-autoplay-on-hover
      ><slide v-for="img in [1]" :key="img">
        <BaseImage
          v-if="!isMobile"
          :imgSrc="imgUrlDomain + 'assets/images/HeaderWeb.png'"
          mode="mainBanner"
          :isFullUrl="true"
          fluid-grow
          @click="bannerLink('/')"
        ></BaseImage>
        <BaseImage
          v-else
          :imgSrc="imgUrlDomain + 'assets/images/mHeaderWeb.png'"
          mode="mainBanner"
          :isFullUrl="true"
          fluid-grow
          @click="bannerLink('/')"
        ></BaseImage>
      </slide>
      <template #addons>
        <Navigation />
        <Pagination />
      </template>
    </Carousel>

    <div class="coin coin1" :class="{ mobileCoin: isMobile }">
      <BaseImage
        :imgSrc="imgUrlDomain + 'assets/images/coins1.png'"
        :isFullUrl="true"
      />
    </div>
    <div class="coin coin2" :class="{ mobileCoin: isMobile }">
      <BaseImage
        :imgSrc="imgUrlDomain + 'assets/images/coins2.png'"
        :isFullUrl="true"
      />
    </div>
  </div>
</template>

<style scoped>
.bannerContainer {
  position: relative;
}

.coin {
  width: 4.5%;
  position: absolute;
}
.coin.mobileCoin {
  width: 7%;
}

.coin1 {
  top: 52%;
  left: 47%;
  animation-name: float;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.coin1.mobileCoin {
  left: 42%;
}

@keyframes float {
  0% {
    translate: 0;
  }
  50% {
    translate: 0 -50%;
  }
  100% {
    translate: 0;
  }
}

.coin2 {
  top: 50%;
  left: 25%;
  animation-name: flip;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.coin2.mobileCoin {
  left: 18%;
}

@keyframes flip {
  0% {
    translate: 0;
  }
  2.5% {
    translate: 0;
    transform: scaleY(100%);
  }
  7.5% {
    translate: 0 50%;
    transform: scaleY(50%);
  }
  15% {
    transform: scaleY(100%);
    transform: rotateX(180deg);
  }
  20% {
    translate: 0 -100%;
    transform: rotateX(360deg);
  }
  25% {
    transform: rotateX(540deg);
  }
  30% {
    translate: 0 -100%;
    transform: rotateX(360deg);
  }
  35% {
    transform: scaleY(100%);
    transform: rotateX(180deg);
  }
  42.5% {
    translate: 0 50%;
    transform: scaleY(50%);
  }
  47.5% {
    translate: 0;
    transform: scaleY(100%);
  }
  50% {
    translate: 0;
  }
}
</style>

<style>
.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  transform: none;
  background-color: transparent;
  height: 100%;
  top: 0;
  padding: 0 5vw;
}

.carousel__pagination {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  -ms-flex-pack: center;
  padding-left: 0;
  margin: 0 auto 2vw auto;
}

.carousel__pagination-button {
  width: 2vw;
  background-color: #f3ff704a;
}

.carousel__pagination-button.carousel__pagination-button--active {
  background-color: #f3ff70;
}
</style>

<script>
import BaseImage from "@/components/dynamic/BaseImage.vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Pagination, Slide, Navigation } from "vue3-carousel";
import { getCultureID } from "@/currentCulture";
export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    BaseImage,
  },
  data() {
    return {
      bannerList: [],
      bannerListByLang: [],
      showBanner: false,
      imgUrlDomain: process.env.VUE_APP_Media_Domain,
    };
  },
  computed: {
    getBannerList() {
      return this.$store.getters["banner/getBanner"](this.$isMobile());
    },
    isMobile() {
      return this.$isMobile();
    },
  },
  methods: {
    bannerLink(url) {
      if (url.includes(".")) {
        if (!/^https?:\/\//i.test(url)) {
          url = "http://" + url;
        }
        window.open(url);
      } else {
        this.$router.push(url.toLowerCase());
      }
    },
  },
  watch: {
    "$i18n.locale": function (newLanguage) {
      const currentLocaleID = getCultureID(newLanguage);
      this.bannerList = this.$store.getters["banner/getBanner"](
        this.$isMobile()
      );
      this.bannerListByLang = this.bannerList.map((bannerItem) => {
        return bannerItem.banner
          .filter((x) => x.languageId == currentLocaleID)
          .map((banner) => {
            return banner;
          })[0];
      });
    },
  },
  async mounted() {
    let languageCode = "";
    const currentLocaleID = getCultureID(languageCode);
    await this.$store.dispatch("banner/fetchBanner").then(() => {
      this.bannerList = this.$store.getters["banner/getBanner"](
        this.$isMobile()
      );
      this.bannerListByLang = this.bannerList.map((bannerItem) => {
        return bannerItem.banner
          .filter((x) => x.languageId == currentLocaleID)
          .map((banner) => {
            return banner;
          })[0];
      });
    });
  },
};
</script>
