import { ActionTree } from "vuex";
import {
  getLaunchGameUrl,
  getGameList,
  getGameCategories,
} from "@/network/GamesService/Games";
import { GamesStateInterface } from "./state";
import { RootState } from "@/store/state";
import { GamesMutations } from "./mutations";

export const actions: ActionTree<GamesStateInterface, RootState> = {
  async fetchLaunchGameUrl(context, payload) {
    const clientId = process.env.VUE_APP_IdentityServer_ClientId;
    const res = await getLaunchGameUrl(
      payload.GameCode,
      payload.VendorId,
      clientId
    );
    return res;
  },
  async fetchGameList(context, payload) {
    // const vendorId = context.rootGetters["vendors/getVendor"].id;
    const res = await getGameList(54, "");
    context.commit(GamesMutations.SET_GAMES_LIST, res);
  },
  async fetchGameCategory(context, payload) {
    const vendorId = context.rootGetters["vendors/getVendor"].id;
    const res = await getGameCategories(20);
    return res;
  },
};
