import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2854aa72"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "logoContainer" }
const _hoisted_4 = { class: "d-flex justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideBar = _resolveComponent("SideBar")!
  const _component_BaseImage = _resolveComponent("BaseImage")!
  const _component_header_nav_bar = _resolveComponent("header-nav-bar")!
  const _component_b_container = _resolveComponent("b-container")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createVNode(_component_b_container, {
      fluid: "",
      class: _normalizeClass(["headerContainer px-0", { isTop: _ctx.scrollPosition == 0 }])
    }, {
      default: _withCtx(() => [
        _createVNode(_component_b_container, {
          fluid: "",
          class: "px-0 h-100 d-flex align-items-center flex-column"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_b_container, { class: "d-flex justify-content-between align-items-center h-100" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_SideBar),
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_BaseImage, {
                      imgSrc: _ctx.imgUrlDomain + _ctx.logoUrl,
                      mode: "logo",
                      isFullUrl: true,
                      "fluid-grow": "",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/'))),
                      class: "header-logo-left"
                    }, null, 8, ["imgSrc"])
                  ])
                ]),
                _createVNode(_component_BaseImage, {
                  imgSrc: _ctx.imgUrlDomain + _ctx.logoUrl,
                  mode: "logo",
                  isFullUrl: true,
                  "fluid-grow": "",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/'))),
                  class: _normalizeClass(["header-logo-center", { afterLogin: _ctx.isLogin }])
                }, null, 8, ["imgSrc", "class"]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_header_nav_bar)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["class"])
  ]))
}