<template>
  <b-container fluid>
    <b-container class="pb-2 pt-3 pt-md-4 p-md-5 footer">
      <div class="d-flex copyRight-container">
        <div>{{ $t("TheFooter.Copyright") }} © Kaya618</div>
        <div class="d-flex socialContainer">
          <div class="clickable socialIcon">
            <font-awesome-icon :icon="['fab', 'facebook-f']">
            </font-awesome-icon>
          </div>
          <div class="clickable socialIcon">
            <font-awesome-icon :icon="['fab', 'twitter']"></font-awesome-icon>
          </div>
        </div>
      </div>

      <!-- <div class="footer-grid-container">
        <div class="joinUs-container">
          <b-row class="text-start d-none d-md-block">
            <b-col>{{ $t("TheFooter.JoinUs") }}</b-col>
          </b-row>
          <div class="text-start d-flex">
            <b-col class="h2 mt-2"
              ><font-awesome-icon :icon="['fab', 'facebook-f']">
              </font-awesome-icon
            ></b-col>
            <b-col class="h2 mt-2"
              ><font-awesome-icon :icon="['fab', 'twitter']"></font-awesome-icon
            ></b-col>
            <b-col class="h2 mt-2"
              ><font-awesome-icon
                :icon="['fab', 'instagram']"
              ></font-awesome-icon
            ></b-col>
            <b-col class="h2 mt-2"
              ><font-awesome-icon :icon="['fab', 'youtube']"></font-awesome-icon
            ></b-col>
          </div>
        </div>
        <div class="eighteen-container">
          <b-row class="text-start my-4">
            <b-col cols="3"><div class="eighteenText">18+</div></b-col>
            <b-col cols="9" class="gamblingText"
              >{{ $t("TheFooter.Gamblingcanbeaddictive") }},<br />{{
                $t("TheFooter.Playresponsibly")
              }}</b-col
            >
          </b-row>
        </div>

        <div class="information-container">
          <b-row class="text-start d-none d-md-block">
            <b-col>{{ $t("TheFooter.Information") }}</b-col>
          </b-row>
          <b-row
            class="text-start my-2 informationText"
            cols-sm="3"
            cols-md="3"
            cols-lg="2"
          >
            <router-link :to="'/aboutus'">
              <b-col class="my-2">{{ $t("TheFooter.About") }}</b-col>
            </router-link>
            <router-link :to="'/terms'">
              <b-col class="my-2">{{ $t("TheFooter.Terms") }}</b-col>
            </router-link>
            <router-link :to="'/privacypolicy'">
              <b-col class="my-2">{{ $t("TheFooter.Privacy") }}</b-col>
            </router-link>
            <router-link :to="'/responsiblegaming'">
              <b-col class="my-2">{{
                $t("TheFooter.ResponsibleGaming")
              }}</b-col>
            </router-link>
          </b-row>
        </div>

        <div class="talkToUs-container">
          <div class="d-flex justify-content-end w-100 my-4">
            <div>
              <b-button variant="talkToUs">{{ $t("TheFooter.TalktoUs") }}</b-button>
            </div>
          </div>
        </div>
      </div> -->
    </b-container>
  </b-container>
</template>

<style scoped>
.footer {
  border-top: 1px solid #f3ff70;
}
:deep(.socialIcon svg path) {
  fill: #f3ff70;
}

.socialIcon,
.socialIcon svg {
  width: 2rem;
  height: 2rem;
}

.socialIcon {
  margin-inline: 1rem;
}

.socialContainer {
  margin-left: auto;
}

.informationText > a {
  color: #fff !important;
  white-space: nowrap;
  text-decoration: none !important;
}
.eighteenText {
  border-radius: 100%;
  border: 2px solid white;
  width: 100%;
  height: auto;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gamblingText {
  font-size: 8px;
  font-weight: 400;
  /* font-family: "Lato"; */
  text-align: start;
}
.btn-talkToUs {
  background: linear-gradient(104.86deg, #9f4141 0%, #581b1a 100%);
  border-radius: 100px 100px 0px 100px !important;
  border: none;
}
.footer-grid-container {
  display: grid;
  grid-template-areas:
    "joins . . infor . . ."
    "joins . . infor . . copys"
    "eight . . infor . . .";
  grid-auto-columns: 20% 1fr 20%;
}
.joinUs-container {
  grid-area: joins;
}
.information-container {
  grid-area: infor;
}
.talkToUs-container {
  grid-area: talks;
  display: flex;
}
.copyRight-container {
  grid-area: copys;
}
.eighteen-container {
  grid-area: eight;
}
@media (max-width: 1200px) {
  .copyRight-container {
    flex-direction: column;
  }

  .socialContainer {
    margin: 0.4rem auto;
    justify-content: center;
  }

  .socialIcon,
  .socialIcon svg {
    width: 1.4rem;
    height: 1.4rem;
  }
}
</style>
