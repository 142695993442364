import { ActionTree } from "vuex";
import memberService from "@/network/MemberService/Member";
import { MemberStateInterface } from "./state";
import { RootState } from "@/store/state";
import { MemberMutations } from "./mutations";
import CryptoJS from "crypto-js";

export const actions: ActionTree<MemberStateInterface, RootState> = {
	async fetchMember(context) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const res = await memberService.getMember(loginId);
		context.commit(MemberMutations.SET_MEMBER, res);
	},
	async fetchMemberBank(context) {
		if (!context.state.memberBank == undefined) {
			if (Object.keys(context.state.memberBank).length !== 0) {
				return;
			}
		}
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const res = await memberService.getMemberBank(loginId).then((data) => {
			if (!(data == undefined || Object.keys(data).length === 0)) {
				context.commit(MemberMutations.SET_IS_MEMBERBANK_EXIST, { isMemberBankExist: true });
			}
			context.commit(MemberMutations.SET_ON_LOAD_MEMBERBANK, { onLoadMemberBankStatus: true });
			return data;
		});
		context.commit(MemberMutations.SET_MEMBER_BANK, res);
	},
	async addMemberBank(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		return await memberService.addMemberBank(loginId, payload.bankId, payload.accountHolder, payload.accountNumber);
	},
	async changePassword(_, payload) {
		await memberService.changePassword(payload.phoneNo, payload.currentPassword, payload.newPassword);
	},
	async resetPassword(_, payload) {
		await memberService.resetPassword(payload.phoneNo, payload.token, payload.newPassword);
	},
	async updateDOB(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		await memberService.updateMemberDOB(loginId, payload.dobirth);
	},
	async forgotPassword(context, payload) {
		const token = await context.dispatch("identityServer/fetchRegisterToken", null, { root: true });
		await memberService.forgotPassword(payload.phoneNo, token);
	},
	async getMemberCount(context) {
		const token = await context.dispatch("identityServer/fetchRegisterToken", null, { root: true });
		const res = await memberService.getMemberCount(token);
		context.commit(MemberMutations.SET_MEMBER_COUNT, res);
	},
	async fetchAspUserClient(context, payload) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const res = await memberService.fetchAspUserClient(memberId, payload.clientId);
		context.commit(MemberMutations.SET_USER_CLIENT, res);
	},
	async exchangeToken(context) {
		const ipAddress = await context.dispatch("ipAddress/fetchIPAddress", null, { root: true });
		await memberService
			.exchangeToken(ipAddress)
			.then((data) => {
				const expirationDate = new Date().getTime() + data.expiresIn * 1000;
				const cipher = CryptoJS.AES.encrypt(
					data.accessToken,
					CryptoJS.enc.Utf8.parse(process.env.VUE_APP_Encryption_Key),
					{
						iv: CryptoJS.enc.Utf8.parse(process.env.VUE_APP_Initial_Vector),
						mode: CryptoJS.mode.CBC,
					}
				);
				//Insert value into local storage
				localStorage.setItem("token", cipher.toString());
				localStorage.setItem("tokenExpiration", expirationDate.toString());
			})
			.catch(() => {
				localStorage.removeItem("token");
			});
	},

	async fetchMemberReferral(context) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const res = await memberService.getMemberReferral(loginId);
		context.commit(MemberMutations.SET_MEMBER_REFERRAL, res);
	}
};
