<template>
  <b-row align-h="center" class="navContainer d-md-flex">
    <div class="navContent">
      <nav class="me-4">
        <NavBarLink> </NavBarLink>
      </nav>
      <b-dropdown variant="transparent px-0" class="langDropdown">
        <template #button-content>
          <img :src="getCurrentLang.imgUrl" class="selectedLangIcon"
        /></template>
        <b-dropdown-item
          v-for="item in clientlanguagelist"
          :key="`Lang${item.id}`"
          @click="changeLang(item.languageCountryCode.substring(0, 2))"
        >
          <img :src="langs[item.languageCountryCode.substring(0, 2)].imgUrl" class="selectedLangIcon" />
          {{ langs[item.languageCountryCode.substring(0, 2)].name }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </b-row>
</template>

<style scoped>
nav {
  display: flex;
  justify-content: center;
  margin-right: 3rem;
}

nav a {
  font-weight: bold;
  color: #f3ff70;
}

.navContainer {
  width: 100%;
}

.navContent {
  display: flex;
  align-items: center;
  justify-content: end;
}

.langDropdown {
  text-transform: capitalize;
  text-align: center;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 999px;
  padding-inline: 0.5rem;
}

:deep(.dropdown-menu) {
  background: rgba(0, 0, 0, 0.6);
}

:deep(.dropdown-item) {
  color: white;
}

:deep(.dropdown-item:hover) {
  color: white;
  background: rgba(0, 0, 0, 0.7);
}

.selectedLangIcon {
  width: 30px;
  height: auto;
  border-radius: 50px;
  box-sizing: border-box;
  border: 2px white solid;
  box-shadow: inset 0 0 5px rgb(0 0 0 / 80%);
  margin-inline: 0.1rem 0.2rem;
}

@media (max-width: 1200px) {
  nav {
    display: none;
  }
  .navContent {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (max-width: 767px) {
  .navContent {
    width: 100%;
  }

  nav {
    padding: 10px 0px 2px;
  }
}
</style>
<style>
nav a.router-link-exact-active > .navLink,
nav a.router-link-active > .navLink {
  font-weight: bold;
  color: #f3ff70;
}
</style>
<script>
import NavBarLink from "@/components/content/Header/HeaderNavLink";
export default {
  data() {
    return {
      langs: {
        en: {
          name: "English",
          imgUrl: process.env.VUE_APP_Media_Domain + "assets/images/en-US.svg",
        },
        zh: {
          name: "中文",
          imgUrl: process.env.VUE_APP_Media_Domain + "assets/images/zh-CN.svg",
        },
        ms: {
          name: "Malay",
          imgUrl: process.env.VUE_APP_Media_Domain + "assets/images/ms-MY.svg",
        },
      },
      clientlanguagelist: []
    };
  },
  components: {
    NavBarLink,
  },
  computed: {
    getCurrentLang() {
      return this.langs[this.$root.$i18n.locale];
    },
  },
  methods: {
    changeLang(currentLang) {
      this.$root.$i18n.locale = currentLang;
      localStorage.setItem("locale", currentLang);
    },
  },
  async created() {
    await this.$store.dispatch("language/fetchClientLanguageList").then(() => {
       this.clientlanguagelist = this.$store.getters["language/getClientLanguage"];      
    });
  },
};
</script>
