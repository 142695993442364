// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/arrow-down.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".backToTopButtonContainer[data-v-1096df13]{display:flex;flex-direction:column;align-items:center;gap:.5rem;position:fixed;bottom:20%;right:10%;z-index:100;width:4rem;text-align:center}.backToTopButton[data-v-1096df13]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-repeat:no-repeat;background-size:2.5rem;background-color:rgba(0,0,0,.5);filter:invert(100%);border-radius:50%;width:4rem;height:4rem;transform:rotate(180deg)}.backToTopText[data-v-1096df13]{font-size:.7em;font-weight:600;white-space:nowrap}@media (max-width:900px){.backToTopButtonContainer[data-v-1096df13]{bottom:18%;left:50%;width:3rem;translate:-50%}.backToTopButton[data-v-1096df13]{background-size:1.5rem;width:2.5rem;height:2.5rem}.backToTopText[data-v-1096df13]{font-size:.7em;font-weight:600;white-space:normal}}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
