<template>
  <b-container fluid class="mt-3 mt-md-5">
    <b-container class="pb-5">
      <div
        class="d-flex flex-wrap justify-content-between align-items-center text-center gamesTitleContainer"
      >
        <div class="d-flex align-items-center gamesTitle">
          <!-- <base-image :imgSrc="homeHotSlotIcon" :isFullUrl="false"></base-image> -->
          <span class="homeSlot-title">{{ $t("HomeHotSlot.Games") }}</span>
        </div>
        <!-- <template v-if="isLogin">
          <div class="moreGames" @click="startPlay">
            {{ $t("HomeHotSlot.moreGames") }}
          </div>
        </template>
        <template v-else>
          <div class="moreGames" v-b-modal="'login-modal'">
            {{ $t("HomeHotSlot.moreGames") }}
          </div>
        </template> -->
        <div class="searchContainer">
          <input
            type="text"
            :placeholder="$t('HomeHotSlot.Search')"
            v-model="searchTerm"
          />
        </div>
      </div>
      <div class="slotGameContainer">
        <div
          class="gameItemContainer"
          v-for="gameItem in filteredGameList"
          :key="gameItem"
        >
          <div class="gameItem">
            <div class="gameItem-image">
              <base-image
                :imgSrc="imageLinkDomain + imageLinkPath + gameItem.gameImage"
                :isFullUrl="true"
              ></base-image>
            </div>
            <div class="gameTitle">{{ gameItem.gameName }}</div>
            <button @click="launchGame(gameItem)">
              {{ $t("HomeHotSlot.DemoPlay") }}
            </button>
          </div>
        </div>
      </div>
    </b-container>
  </b-container>
</template>
<style scoped>
.homeSlot-title {
  color: #f3ff70;
  font-size: 1.5rem;
  font-weight: 700;
  white-space: nowrap;
  text-transform: uppercase;
}
.moreGames {
  cursor: pointer;
  font-size: 1.5rem;
}
.slotGameContainer {
  display: grid;
  gap: 3rem 1rem;
  grid-template-columns: repeat(auto-fill, 9rem);
  justify-content: space-between;
}

.gameItemContainer {
  position: relative;
}
.gamesTitleContainer {
  margin-block: 2rem;
}

.gameItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 100%;
  gap: 1.2rem;
}

.gameItem-image {
  width: 100%;
}

.gameItem-image img {
  scale: 1.2;
}

.gameItem button {
  margin-top: auto;
  text-transform: uppercase;
  background: #f3ff70;
  color: black;
  border: 2px solid transparent;
  border-radius: 999px;
  padding: 0.3rem 0;
  width: 100%;
  max-width: 9rem;
}

.gameItem button:hover {
  background: black;
  color: #f3ff70;
  border-color: #f3ff70;
  box-shadow: 0 0 8px 0 #f3ff70;
}

.gameTitle {
  color: #ffffff;
}

/*
.gameItemContainer .gameItem-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #2b8fc8;
  display: none;
}
.gameItemContainer:hover .gameItem-overlay {
  display: flex;
  background: rgba(0, 0, 0, 0.7);
  padding: 1rem;
  justify-content: center;
  align-items: center;
}
*/

.searchContainer input,
.searchContainer input:focus-visible {
  background-image: url("@/assets/search.svg");
  background-repeat: no-repeat;
  background-size: 1.5rem;
  background-position: 0.7rem;
  padding: 0.5rem;
  border: 1px solid #f3ff70;
  border-radius: 999px;
  background-color: #f3ff704a;
  outline: none;
  text-indent: 2.2rem;
  color: #f3ff70;
}

.searchContainer input::placeholder {
  color: #f3ff7077;
}

.searchContainer input:focus {
  box-shadow: 0 0 8px 0 #f3ff70;
}

@media (max-width: 767px) {
}
@media (max-width: 1200px) {
  .slotGameContainer {
    justify-content: space-around;
  }

  .gamesTitleContainer {
    gap: 1rem;
    margin-block: 0 2rem;
  }

  .gamesTitle {
    margin-inline: auto;
  }

  .gameItem {
    gap: 1rem;
  }

  .gameTitle {
    font-size: 0.9em;
    line-height: 1;
  }

  .homeSlot-title {
    width: 100%;
  }

  .searchContainer,
  .searchContainer input {
    width: 100%;
    padding-inline: 1rem;
  }
}
@media (max-width: 1640px) {
}
</style>
<script>
import { GameType } from "@/common/GameType.ts";
import BaseImage from "@/components/dynamic/BaseImage.vue";

export default {
  data() {
    return {
      imageLinkDomain: process.env.VUE_APP_Media_CentralizedDomain_Domain,
      imageLinkPath: "VendorGameImage/kaya918/",
      gameList: [
        {
          gameImage: "blackpink.jpeg",
          gameName: "BlackPink",
        },
        {
          gameImage: "classicDiamondX5.jpeg",
          gameName: "Classic Diamond x5",
        },
        {
          gameImage: "fongshen.jpeg",
          gameName: "Feng Shen",
        },
        {
          gameImage: "illuvium.jpeg",
          gameName: "Illuvium",
        },
        {
          gameImage: "axieUniverse.jpeg",
          gameName: "Axie Universe",
        },
        {
          gameImage: "luckyCoins.jpeg",
          gameName: "Lucky Coins",
        },
        {
          gameImage: "imperialKingdom.jpeg",
          gameName: "Imperial Kingdom",
        },
        {
          gameImage: "princessTheEvilWitch.jpeg",
          gameName: "Princess The Evil Witch",
        },
        {
          gameImage: "happyLantern.jpeg",
          gameName: "Happy Lantern",
        },
        {
          gameImage: "PandasRealm.jpeg",
          gameName: "Panda's Realm",
        },
        {
          gameImage: "highwayKings.jpeg",
          gameName: "Highway Kings",
        },
        {
          gameImage: "dragonsWish.jpeg",
          gameName: "Dragon's Wish",
        },
        {
          gameImage: "menInBlack.jpeg",
          gameName: "Men In Black",
        },
        {
          gameImage: "dazzlingStar.jpeg",
          gameName: "Dazzling Star",
        },
      ],
      searchTerm: "",
    };
  },
  components: { BaseImage },
  methods: {
    async fetchGameList() {
      await this.$store.dispatch("games/fetchGameList").then(() => {
        this.gameList = this.$store.getters["games/getGameList"].games;
      });
    },
    async launchGame(gameItem) {
      let loader = this.$loading.show();
      await this.$store
        .dispatch("games/fetchLaunchGameUrl", {
          GameCode: gameItem.gameCode,
          VendorId: gameItem.vendorId,
        })
        .then((url) => {
          loader.hide();
          window.open(url, "_blank");
        })
        .catch(() => {
          loader.hide();
          let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
          this.toast.error(this.$t("HomeHotSlot.LaunchGameError"), {
            position: toastPosition,
          });
        });
    },
    // async startPlay() {
    //   let loader = this.$loading.show();
    //   await this.$store
    //     .dispatch("downloadLinks/fetchDownloadLinks", { downloadType: "IOS" })
    //     .then(() => {
    //       loader.hide();
    //       const tempGameUrl =
    //         this.$store.getters["downloadLinks/getDownloadLinks"](
    //           "IOS"
    //         ).downloadUrl;
    //       window.open(tempGameUrl, "_blank");
    //     });
    // },
    // async startPlay() {
    // 	let loader = this.$loading.show();
    // 	await this.$store
    // 		.dispatch("games/fetchLaunchGameUrl", { GameType: GameType.SLOTS, Lang: localStorage.getItem("locale") })
    // 		.then((url) => {
    // 			loader.hide();
    // 			window.open(url, "_blank");
    // 		})
    // 		.catch(() => {
    // 			loader.hide();
    // 			let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
    // 			this.toast.error(this.$t("HomeHotSlot.LaunchGameError"), { position: toastPosition });
    // 		});
    // },
    async maintenancepopup() {
      let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
      this.toast.error(this.$t("HomeHotSlot.GameMaintenance"), {
        position: toastPosition,
        timeout: 0,
        //onClose: () => this.redirectHomePage(),
      });
    },
  },
  computed: {
    filteredGameList() {
      if (this.searchTerm == "") return this.gameList;
      return this.gameList.filter((game) => {
        return game.gameName
          .toLowerCase()
          .replaceAll(" ", "")
          .includes(this.searchTerm.toLowerCase().replaceAll(" ", ""));
      });
    },
    isLogin() {
      return this.$store.getters["identityServer/getLoginStatus"];
    },
    checkIsMemberBankExist() {
      return this.$store.getters["member/getIsMemberBankExist"];
    },
    isMaintenance() {
      return this.$store.getters["vendors/getVendor"].maintenance;
    },
  },
  async mounted() {
    this.fetchGameList();
  },
};
</script>
