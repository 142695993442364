export default {
	SlotsGame: "Permainan Slot",
	LIVECASINO: "LIVE CASINO",
	PlayNow: "Main Sekarang",
	LaunchGameError: "Permainan pelancaran gagal, sila hubungi Khidmat Pelanggan kami",
	Seemore: "Lihat Lagi",
	HOTGAMES: "PERMAINAN TOP SLOT",
	moreGames: "Banyak Lagi",
	Play: "BERMAIN",
	GameMaintenance: "Permainan sedang diselenggara. Sila cuba sebentar lagi.",
	Games: "Games",
	Search: "Cari",
	DemoPlay: "Main Demo"
};
