export default {
	SlotsGame: "老虎机游戏",
	LIVECASINO: "在线赌场",
	PlayNow: "开始游戏",
	LaunchGameError: "启动游戏失败，请联系客服",
	Seemore: "观看更多",
	HOTGAMES: "热门老虎机游戏",
	moreGames: "更多游戏",
	Play: "开始",
	GameMaintenance: "游戏正在维护中。 请稍后再试。",
	Games: "游戏",
	Search: "搜索",
	DemoPlay:"试玩"
};
