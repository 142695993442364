import httpClient from "./HttpClient";

const getLaunchGameUrl = (
	GameCode : string,
	VendorId : number,
	ClientId: string,
): Promise<string> => {
	const endPoint = `/api/Games/GetKayaLaunchGame`;
	const params = {
		GameCode: GameCode,
		VendorId: VendorId,
		ClientId: ClientId,
	};

	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getGameList = (vendorId: number, gamesCategory: string): Promise<string> => {
	const endPoint = `/api/Games/AllGames`;
	const params = {
		vendorIds: vendorId,
		gamesCategory: gamesCategory,
	};

	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getGameCategories = (vendorId: number): Promise<string> => {
	const endPoint = `/api/Games/${vendorId}/Categories`;
	const params = {
		vendorId: vendorId,
	};

	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

export { getLaunchGameUrl, getGameList, getGameCategories };
