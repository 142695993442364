<template>
  <b-button
    class="navbar-toggler d-md-none"
    type="button"
    @click="isOpen = true"
    variant="none"
  >
    <i class="fa fa-bars fa-2x"></i>
  </b-button>
  <Slide
    class="sidebarContainer"
    :isOpen="isOpen"
    :burgerIcon="false"
    :crossIcon="false"
    :closeOnNavigation="true"
    @closeMenu="isOpen = false"
    noOverlay
  >
    <!-- <div class="offcanvas-header">
			<h5 class="offcanvas-title" id="offcanvasNavbarLabel">
				<BaseImage :imgSrc="logoUrl" mode="logo" :isFullUrl="false" fluid-grow></BaseImage>
			</h5>
			<b-button class="navbar-toggler" type="button" data-bs-dismiss="offcanvas" aria-label="Close" variant="none">
				<i class="fa fa-bars fa-2x"></i>
			</b-button>
		</div> -->
    <div class="sidebarContainer px-0 py-5 px-4" id="page-wrap">
      <ul class="navbar-nav justify-content-end flex-grow-1 p0">
        <template v-for="navbar in navbarList" :key="navbar.title">
          <template
            v-if="!navbar.ignoreMaintenance && isMaintenance && isLogin"
          >
            <a class="nav-item d-flex" role="button" @click="maintenancepopup">
              <!-- <div class="navIconContent">
                <font-awesome-icon :icon="navbar.icon" class="navIcon" />
              </div> -->
              <div class="nav-title">{{ $t("SideBar." + navbar.title) }}</div>
            </a>
          </template>
          <template
            v-else-if="navbar.isRouteLink === true && navbar.isPopUp === false"
          >
            <router-link class="nav-item d-flex" :to="navbar.link">
              <!-- <div class="navIconContent">
                <font-awesome-icon :icon="navbar.icon" class="navIcon" />
              </div> -->
              <div class="nav-title">{{ $t("SideBar." + navbar.title) }}</div>
            </router-link>
          </template>
          <template
            v-else-if="navbar.isRouteLink === false && navbar.isPopUp === true"
          >
            <a
              class="nav-item d-flex"
              role="button"
              v-if="!navbar.afterLogin || isLogin"
              v-b-modal="navbar.link"
            >
              <!-- <div class="navIconContent">
                <font-awesome-icon :icon="navbar.icon" class="navIcon" />
              </div> -->
              <div class="nav-title">{{ $t("SideBar." + navbar.title) }}</div>
            </a>
          </template>
          <template v-else>
            <template
              v-if="(navbar.validateLogin && isLogin) || !navbar.validateLogin"
            >
              <a
                class="nav-item d-flex"
                role="button"
                @click="doPopUp(navbar.link)"
                v-if="!navbar.afterLogin || isLogin"
              >
                <div class="navIconContent">
                  <font-awesome-icon :icon="navbar.icon" class="navIcon" />
                </div>
                <div class="nav-title">{{ $t("SideBar." + navbar.title) }}</div>
              </a>
            </template>
            <template v-else-if="navbar.validateLogin && !isLogin">
              <a
                class="nav-item d-flex"
                role="button"
                v-b-modal="'login-modal'"
              >
                <div class="navIconContent">
                  <font-awesome-icon :icon="navbar.icon" class="navIcon" />
                </div>
                <div class="nav-title">{{ $t("SideBar." + navbar.title) }}</div>
              </a>
            </template>
          </template>
        </template>
      </ul>
    </div>
  </Slide>
</template>

<style scoped>
.navbar-toggler {
  height: 30px;
  width: 30px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.2);
}

.navbar-toggler svg {
  scale: 0.8;
}

:deep(.svg-inline--fa.fa-bars path) {
  fill: #f3ff70;
}

.sidebarContainer {
  background-color: transparent;
}

.nav-item {
  padding: 1rem 0;
  text-decoration: none;
  justify-content: center;
  border-bottom: 1px solid white;
}

.navbar-nav .nav-item:last-child {
  border: none;
}

.nav-title {
  color: white;
  text-shadow: 0 0 5px #a2ff00;
}

.router-link-active.nav-item,
.router-link-exact-active.nav-item,
.router-link-active .nav-title,
.router-link-exact-active .nav-title {
  font-weight: bold;
  color: #f3ff70;
}

.navIconContent {
  margin: 0 1rem 0 2rem;
  width: 1.5rem;
}
</style>
<style>
.bm-menu {
  padding-top: 0px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), 90%, transparent);
}

.bm-menu::after {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  height: 100%;
  width: 1px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.1),
    white,
    rgba(255, 255, 255, 0.1)
  );
  box-shadow: 0px 0px 5px 3px rgba(255, 255, 255, 0.1);
}

.bm-menu .bm-item-list {
  margin-left: 0px;
  padding-bottom: 60px;
  font-size: 1rem;
}
</style>
<script>
import { navBar } from "@/constant";
import { Slide } from "vue3-burger-menu";
import { GameType } from "@/common/GameType.ts";

export default {
  data() {
    return {
      logoUrl: "kaya/assets/images/NavBar/live22.png",
      isOpen: false,
    };
  },
  components: {
    Slide,
  },
  computed: {
    isLogin() {
      return this.$store.getters["identityServer/getLoginStatus"];
    },
    navbarList() {
      var navbarFiltered = navBar.filter((i) => i.view.includes("mobile"));
      if (!this.isLogin) {
        navbarFiltered = navbarFiltered.filter((i) => i.afterLogin == false);
      }
      return navbarFiltered;
    },
    isMaintenance() {
      return this.$store.getters["vendors/getVendor"].maintenance;
    },
  },
  methods: {
    doPopUp(popUpName) {
      if (popUpName == "logout") {
        this.logOut();
      } else if (popUpName == "slot") {
        this.playSlot();
      } else if (popUpName == "casino") {
        this.playCasino();
      }
    },
    logOut() {
      let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
      this.$store.dispatch("identityServer/logOut");
      this.toast.success(this.$t("HeaderLogin.LogoutSuccess"), {
        position: toastPosition,
        timeout: 2000,
        onClose: () => this.redirectHomePage(),
      });
    },
    async playSlot() {
      let loader = this.$loading.show();
      await this.$store
        .dispatch("downloadLinks/fetchDownloadLinks", { downloadType: "IOS" })
        .then(() => {
          loader.hide();
          const tempGameUrl =
            this.$store.getters["downloadLinks/getDownloadLinks"](
              "IOS"
            ).downloadUrl;
          window.open(tempGameUrl, "_blank");
        });
      // await this.$store
      // 	.dispatch("games/fetchLaunchGameUrl", { GameType: GameType.SLOTS, Lang: localStorage.getItem("locale") })
      // 	.then((url) => {
      // 		loader.hide();
      // 		window.open(url, "_blank");
      // 	})
      // 	.catch(() => {
      // 		loader.hide();
      // 		let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
      // 		this.toast.error(this.$t("HomeHotSlot.LaunchGameError"), { position: toastPosition });
      // 	});
    },
    async playCasino() {
      let loader = this.$loading.show();
      await this.$store
        .dispatch("games/fetchLaunchGameUrl", {
          GameType: GameType.LIVE_CASINO,
          Lang: localStorage.getItem("locale"),
        })
        .then((url) => {
          loader.hide();
          window.open(url, "_blank");
        })
        .catch(() => {
          loader.hide();
          let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
          this.toast.error(this.$t("HomeHotSlot.LaunchGameError"), {
            position: toastPosition,
          });
        });
    },
    redirectHomePage() {
      window.location.href = "/";
    },
    closeSidebar() {
      this.$root.$emit("bv::toggle::collapse", "offcanvasNavbar");
    },
    async maintenancepopup() {
      let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
      this.toast.error(this.$t("HomeHotSlot.GameMaintenance"), {
        position: toastPosition,
        timeout: 0,
        //onClose: () => this.redirectHomePage(),
      });
    },
  },
};
</script>
