<template>
  <HomeBanner></HomeBanner>
  <!-- <AnnouncementView></AnnouncementView> -->
  <!-- <HomeDownload></HomeDownload> -->
  <!-- <HomeSwitchSite></HomeSwitchSite> -->
  <HomeHotSlot></HomeHotSlot>
  <!-- <HomePromotion></HomePromotion> -->
  <!-- <HomeHistoryCompany></HomeHistoryCompany> -->
</template>
<style>
.homeTitle {
  /* font-family: "SF Pro Display"; */
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 36px;
  color: #fff;
}
</style>
<script>
// import AnnouncementView from "@/components/content/Announcement/AnnouncementView.vue";
import HomeBanner from "@/components/content/Home/HomeBanner.vue";
import HomeHotSlot from "@/components/content/Home/HomeHotSlot.vue";
// import HomeDownload from "@/components/content/Home/HomeDownload.vue";
// import HomePromotion from "@/components/content/Home/HomePromotion.vue";
// import HomeHistoryCompany from "@/components/content/Home/HomeHistoryCompany.vue";
// import HomeSwitchSite from "@/components/content/Home/HomeSwitchSite.vue";

export default {
  props: ["token", "time"],
  data() {
    return {};
  },
  components: {
    HomeBanner,
    // AnnouncementView,
    // HomeSwitchSite,
    // HomeDownload,
    HomeHotSlot,
    // HomePromotion,
    // HomeHistoryCompany,
  },
  methods: {},
};
</script>
